<template>
   <main id="login-screen" class="main">

      <div class="container-alt">

         <h1 class="text-center mb-3">Accedi</h1>

         <Errors :errors="errors" />

         <form class="login" @submit.prevent="login_user({username,password})">
            
            <div class="mb-3">
               <label for="email" class="form-label">Email *</label>
               <input required v-model="username" type="text" placeholder="name@domain.ext" id="email" class="form-input bg-transparent" />
            </div>

            <div class="mb-1">
                  <label for="password" class="form-label">Password *</label>
                  <div class="pass-wrap">
                     <input required v-model="password" type="password" placeholder="Password" id="password" class="form-input bg-transparent mb-0" />
                     <span class="switcher" @click="switchPasswordView($event.target)"><i class="fa-regular fa-eye"></i></span>
                  </div>
            </div>

            <div class="mb-3">
               <small>Password dimenticata? 
               <a  class="fw-bold" :href="resetlink" target="_blank">Recupera</a></small>
               <!-- <router-link to="/resetpassword" class="fw-bold">Recupera</router-link> -->
            </div>
            
            <div class="text-center mb-5">
               <button class="btn btn-primary" type="submit" style="width:120px">
                  
                  <span v-if="! login_in_corso">{{loginButtonText}}</span>

                  <span v-else>
                     <span class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                     </span>
                  </span>
               </button>
            </div>


            <div class="text-center mb-2">
               Non hai un account?
            </div>
            <div class="text-center">
               <router-link to="/register" class="btn btn-secondary">Registrati</router-link>
            </div>
         </form>

      </div>
   
   </main>
</template>

<script>
import Errors from '@/components/Errors.vue';
import { mapActions } from "vuex";
import { Utils } from '@/inc/Utils';
import { Vars } from '@/inc/Variables';

export default {
  components: { Errors },
  name: 'Login',
  mixins: [Utils],
  data(){
     return {
        errors: [],
        username: 'formability',
        password: 'fozzaduocu15',
        loginButtonText: 'Accedi',
        login_in_corso: false,
        resetlink: Vars.home_url + '/wp-login.php?action=lostpassword'
     }
  },
  methods: {
   ...mapActions("account", ["login"]),

   login_user(payload){
      
      const vm = this;
      this.errors = [];
      this.login_in_corso = true;

      payload.agent = {
         device: navigator.userAgent,
         os: this.getOS()
      };

      this.login(payload).then( (res) => {
         vm.login_in_corso = false;

         if( res.errors ){
            vm.errors = res.errors;
         }
         
         if( res.error ){
            vm.errors.push({text: res.error});
         }

      })
   }
  },
  mounted(){
     window.scrollTo(0,0);
  }
};
</script>

<style scoped>
form.login{
   max-width: 450px;
   width: 100%;
   margin: 0 auto;
}
</style>